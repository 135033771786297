.navBar{
    /* padding: 15px 20px; */
    background-color: white !important;
    height: 50px;

}
.navbar-brand{
    /* padding-left: 20px; */
    padding: 0 0 0 20px;
    font-weight: 600;
    font-family: 'Julius Sans One';
    letter-spacing: 2px;

}

.navBar li {
    display: inline;
    padding: 0 15px;
    background-color: white;
}

.navBar a {
    text-decoration: none;
    text-transform: none;
    color: black;
}
.navBar a:link{
    text-decoration: none;
    text-transform: none;
    color: black !important;
}

navbar-light .navbar-nav .nav-link {
    color:black !important;

    background-color: white !important;
    
}

.navbar-nav, .navbar-collapse, .navBackground {
    background-color: white;
    padding-left: 20px;
}
.NavItem{

    background-color: white !important;
}