.ResumeBackground{
    background-color: white;
    color: black;
    min-height: 100vh;
    /* min-width: 100vh; */
    width: 75%;
    margin: auto;
    padding-bottom: 50px;
}

.skillListing{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10px;
}

#WorkExperience {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
}

.workExpBackground {
    display: flex;
    justify-content: center;
}

#WorkExperience article {
    width: 80%;
    text-justify: newspaper;
}

.Company p:last-child{
    padding: 0;
    font-weight: 700;
    color: red;
    letter-spacing: normal;
}

.RelevantExp li{
    list-style-type: none;
    width: 80%;

}

/* .RelevantExp article p {
    width: 80%;
    text-justify: newspaper;
    letter-spacing: normal;
    font-weight: normal;


} */

.RelevantExp article{
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-left: 40px;

} 

@media only screen and (max-width: 720px) {
    .skillListing{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 10px;
    }
}
