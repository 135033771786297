.graphicDisplay{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: auto;
    width: 90%;
    padding-bottom: 30px;
}

.imageHeight{
    height: 200px;
    width: auto;
    background-position: 50% 50%;
     background-repeat: no-repeat;
     background-size: cover;
     cursor:pointer;
}
.imageHeight-stem{
    height: 200px;
    width: auto;
    background-position: top center;
     background-repeat: no-repeat;
     background-size: cover;
     cursor:pointer;
}
.imageHeightHidden{
    height: 200px;
    width: auto;
    background-color: rgba(0, 0, 0, 0.911);
    opacity: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    padding: 15px;
    text-align: center;
    /* display: none; */
}

.displayedImage {
    height: 90vh;
    width: auto;
    cursor:pointer;
}

@media only screen and (max-width: 600px) {.graphicDisplay{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    width: 90%;
}

.imageHeight{
    height: 150px;
    width: auto;
    background-position: 50% 50%;
     background-repeat: no-repeat;
     background-size: cover;
}
.displayedImage {
    height: auto;
    width: auto;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */