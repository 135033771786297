.contactDisplay{
    margin: auto;
    width: 80%;
    display: flex;
    align-items:center;
    height: 67vh;
    justify-content: center;
}

.ui.list>.item {
    margin-top: 20px;
    padding-top: 20px;
}

