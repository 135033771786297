@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap');

h2 {
    font-size: 4.5em;
    word-break: keep-all;
    font-family: 'Julius Sans One', sans-serif;
    margin: 0;
    letter-spacing: -4px;
    font-weight: 300;
}
h3 {
    font-size: 3.2em;
    word-break: keep-all;
    font-family: 'Julius Sans One', sans-serif;
    margin: 0;
    letter-spacing: 2px;
    font-weight: 300;
}

.HeaderBody{
    padding: 10px;
}

