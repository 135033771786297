.profile img{
    border-radius: 50%;
    border: 1px black solid;
    width: 270px;
    height: auto;
    margin: 0 20px;

}
.engaged{
    width:100%;
}
.publication{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.publication p:nth-of-type(1n){
    margin-bottom: 30px !important;
}
.publication p {
    text-justify: auto;
    font: normal !important;
    letter-spacing: normal !important;
    font-weight: normal !important;
    font-size: 15px;
    width: 80%;
}
.bottomSection{
    padding-bottom: 45px;
}
.aboutSection{
    width: 90%;
    margin: auto;
    padding-bottom: 70px;
}

.profile{
    display: grid;
    width: 95%;
    grid-template-columns: 1fr 5fr;
    background-color: white;
    margin: auto;
    color: black;
}

.aboutSections {
    margin: auto;
    width: 90%;
}
.Stem p{
    text-justify: auto;
    font: normal !important;
    letter-spacing: normal !important;
    font-weight: normal !important;
    font-size: 15px;
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    
}
.Stem{
    padding-bottom: 20px;
}

.artistcv{
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
}
.cvItem{
    margin-bottom: 30px;
}

.cvItem h5{
    font-weight: 700;
    font-size: 16px;
}

.artistcv p{
    text-justify: auto;
    font: normal !important;
    letter-spacing: normal !important;
    font-weight: normal !important;
    font-size: 15px;
    width: 80%;
    
}
.stemGallery{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 15px;
}

.Statement {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    font-size: xx-large;
    width: 90%;
    line-height: 1.3;
    text-justify: newspaper;
    font-family: 'Open Sans';
    font-weight: 300;
}
.Statement p {
    padding-top: 15px;
    font-size: 13px;
}

.tablerow{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}

.tablerow li {
    list-style-type: none;
    padding: 5px 0;
    text-align: center;
}




@media only screen and (max-width: 720px) {
    .profile img{
        border-radius: 0%;
        border: 1px black solid;
        width: 80%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 20px;
    
    }
    .aboutSection{
        width: 90%;
        margin: auto;
    }
    
    .profile{
        display: grid;
        width: 95%;
        grid-template-columns: 1fr;
        background-color: rgb(255, 255, 255) !important;
        margin: auto;
        color: black;
    }
   
    .aboutSections {
        margin: auto;
        width: 90%;
    }
    .Statement {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        font-size: 1.6em;
        width: 90%;
        line-height: 1.3;
        text-justify: newspaper;
        font-family: 'Open Sans';
        font-weight: 300;
    }
    .Statement p {
        padding-top: 15px;
        font-size: 13px;
    }
    
    .tablerow{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    
    }
    
    .tablerow li {
        list-style-type: none;
        padding: 5px 0;
        text-align: center;
    }
    .artistcv{
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;
    }
    .stemGallery{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    
}
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */