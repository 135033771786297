@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap');

#root{
  height: 100%;
  margin:0;
  width: 100vw;
}

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}

.App {
 /* text-align: center; */
  width: 100% !important;
  display: flex;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
  max-width: 100vw;
  padding: 0;
  margin: 0;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
} */


.copyright > p{
  font-size: 10px !important ;
  text-align: center;
background-color: white;
width: 100vw;
padding: 3px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.video-background {
  background: #000;
  /* background-image: url('../images/dcbackground.png'); */
  background-image: url('../../images/dcartst_logo.png');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  min-width: 90vh;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media screen and (min-device-width : 901px) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
  .mobile-video{
    display: none;
  }
  .desktop-video{
    display: block;
  }
}
/* @media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
  .mobile-video{
    display: none;
  }
  .desktop-video{
    display: block;
  }
} */
/* 
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 277%;
    left: -99%;
    right: 0;
    bottom: 0;
  }
  .mobile-video{
    display: block;
  }

  .desktop-video{
    display: none;
    width: 100%;
  }


} */

/* Smart Phones */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 767px) {

  .video-foreground {
    width: 206%;
    left: -76%;
    right: 0;
    bottom: 0;
  }
  .mobile-video{
    display: block;
  }

  .desktop-video{
    display: none;
    width: 100%;
  }
}

/* Ipad */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 900px) {
  .video-foreground {
    width: 277%;
    left: -99%;
    right: 0;
    bottom: 0;
  }
  .mobile-video{
    display: block;
  }

  .desktop-video{
    display: none;
    width: 100%;
  }

}

/* .content {
   padding: 25px;
   background-color: rgba(#000, .3);
   position: absolute;
   right: 50px;
   bottom: 50px;
} */
/*    
   h1 {
      font-family: 'Roboto Slab', serif;
      margin: 0;
      color: #fff;
   } */