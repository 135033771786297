@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap');
.largeTitleHeader {
    font-size: 4.6rem;
    word-break: keep-all;
    font-family: 'Julius Sans One', sans-serif;
    margin: 0 0 0px 0;
    letter-spacing: -4px;
}

.TitleHeaderSM{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 4px;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: 20px;
}
/* p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 4px;
    margin: 0;
    font-size: 1.3rem;
} */
/* font-family: 'Julius Sans One', sans-serif;
font-family: 'Open Sans', sans-serif; */
.title{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.822);
    padding: 25px 15px;
}
#overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
.homeBackground {

    /* height: 100%; */
    text-align: center;
    display: flex;
    align-content: center; 
    align-items: center;
    color: white;
    justify-content: center;
    height: 88vh;
    flex-flow: column;
    min-width: 100vw;
    background-size: cover;
    background-color:black;
    background-position: center;
    flex: auto;
    /* overflow: hidden !important; */
    overflow-y: hidden !important;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
    /* background-color: darkblue; */
}


.homeBase{
    height: 100%;
    background-color: black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .title{
        width: 100%;
        background-color: rgba(0, 0, 0, 0.822);
        /* padding: 25px 15px; */
        min-height: 30vh;
    }
    .largeTitleHeader {
    /* font-size: 3rem; */
    word-break: keep-all;
    font-family: 'Julius Sans One', sans-serif;
    /* margin: -120px 0 10px 0; */
    letter-spacing: -4px;
}
/* p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 4px;
    margin: 0;
    font-size: 1.3rem;
} */

.homeBackground {
    text-align: center;
    display: flex;
    align-content: center; 
    align-items: center;
    color: white;
    /* justify-content: center; */
    flex-flow: column;
    justify-content: center;
    height: 95vh;
    /* min-height: 91vh; */
    min-width: 100vw;
    background-color: rgb(0, 0, 17);
    flex: auto;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */