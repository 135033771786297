.appDisplay{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: auto;
    padding-top: 20px;
    width: 85%;
    row-gap: 40px;
    padding-bottom: 20px;
}
.ui.segment {
    position: relative;
    background: #fff;
    box-shadow: none;
    margin: 1rem 0;
    padding: 1em 1em;
    border-radius: .28571429rem;
    border: 0;
}
.itemCardSpan {
    grid-column: 1 / span 2;
    margin: 0 0 10px 0;
}
.itemCardSpan2 {
    grid-column: 3 / span 2;
    margin: 0 0 20px 0;
}

.ui.card {
    margin: 0 0;
}

h2, h3 {
    word-break: keep-all;
    font-family: "Julius Sans One",sans-serif !important;
    margin: 0;
    font-weight: 300 !important;
}

h2{
    font-size: 4rem !important;
    letter-spacing: -4px;
}

h3{
    font-size: 3rem !important;
    letter-spacing: 0px;
}

.dropdown-item{
    font-size: 15px !important;
}

/* 
@media only screen and (max-width: 820px) {
    .appDisplay{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: auto;
        padding-top: 20px;
        width: 90%;
        row-gap: 5px;
    }

}

 */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .appDisplay{
        /* display: grid;
        grid-template-columns: repeat(1, 1fr); */
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        padding-top: 20px;
        width: 90%;
        row-gap: 5px;
    }
    
    .itemCardSpan {
        grid-column: 1 / span 2;
        margin: 0 0 10px 0;
    }
    .itemCardSpan2 {
        grid-column: 3 / span 2;
        margin: 0 0 20px 0;
    }
    
    .ui.card {
        margin: 0 0;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .appDisplay{
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        padding-top: 20px;
        width: 85%;
        row-gap: 40px;
    }
    
    .itemCardSpan {
        /* grid-column: 1 / span 2; */
        margin: 0 0 10px 0;
    }
    .itemCardSpan2 {
        /* grid-column: 3 / span 2; */
        margin: 0 0 20px 0;
    }
    
    .ui.card {
        margin: 0 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .appDisplay{
        display: flex;
        flex-wrap: wrap;
        /* flex-direction: row; */
        grid-template-columns: repeat(1, 1fr);
        margin: auto;
        /* padding-top: 20px; */
        width: 90%;
        /* height: auto; */
        /* row-gap: 40px; */
    }
    
    .itemCardSpan {
        grid-column: 1 / span 2;
        margin: 0 0 10px 0;
    }
    .itemCardSpan2 {
        grid-column: 3 / span 2;
        margin: 0 0 20px 0;
    }
    
    .ui.card {
        margin: 0 0;
        padding-bottom: 3px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .appDisplay{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin: auto;
        padding-top: 20px;
        width: 85%;
        row-gap: 40px;
    }
    
    .itemCardSpan {
        grid-column: 1 / span 2;
        margin: 0 0 10px 0;
    }
    .itemCardSpan2 {
        grid-column: 3 / span 2;
        margin: 0 0 20px 0;
    }
    
    .ui.card {
        margin: 0 0;
    }
}