.noBorder{
    border: 0 none !important;
    box-shadow: 0 0 0 none !important;
}
.segments{
    
    box-shadow: 0 0 0 none !important;
    border: 0 none!important;
    border-left: 0 none!important;
}
.meta{
    color: rgb(75, 75, 75) !important;
    font-weight: 600;
}

.ui.horizontal.segments {
    box-shadow: 0 0 0 !important;

}
.itemImage{
    /* width:200px; */
    height: 120px;
    padding: 0 10px ;
    /* width: auto; */
}
.cardImage {
    height: 140px;
    /* width: auto; */
    /* overflow: hidden; */
    background-size: cover;
}

.ui.horizontal.segments>.segment{

    border-left: 0 none!important;
}

.bottomLinks {
    padding: 0;
    margin: 0;
}

.ui.card>.extra, .ui.cards>.card>.extra 
{
    padding: 0;
    margin: 0;
}

.itemContent{
    padding-left: 5px;
}

.cardSize{
    margin-top: 20px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
.itemImage{
    /* width:200px; */
    /* height: 100%; */
    padding: 0 10px ;
    /* width: auto; */
}
.cardSize{
    margin-top: 20px;
    padding-bottom: 0;
    /* height: auto; */
}
.cardImage {
    /* height: 140px; */
    width: 80%;
    /* overflow: hidden; */
    background-size: cover;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .itemImage{
        /* width:200px; */
        height: 120px;
        padding: 0 10px ;
        /* width: auto; */
    }
    .cardImage {
        height: 140px;
        /* width: auto; */
        /* overflow: hidden; */
        background-size: cover;
    }
    

    /* .ui.card>.content, .ui.cards>.card>.content {
        flex-grow: 0;
    } */
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */